import { extendTheme } from "@chakra-ui/react";
import localFont from "next/font/local";

const sf = localFont({
  src: "./pages/res/fonts/SF-Pro-Display-Regular.otf",
  weight: "400",
});

const coa = localFont({ src: "./pages/res/fonts/Corpo-A.ttf" });
const cos = localFont({ src: "./pages/res/fonts/Corpo-S.ttf" });
const cotc = localFont({
  src: "./pages/res/fonts/MBCorpoATitleCond-Regular.otf",
});

const theme = extendTheme({
  config: {
    initialColorMode: "light",
    useSystemColorMode: false,
  },
  components: {
    Button: {
      defaultProps: {
        colorScheme: "brand",
      },
    },
  },
  fonts: {
    ios: sf.style.fontFamily,
    corpoA: coa.style.fontFamily,
    corpoS: cos.style.fontFamily,
    corpoT: cotc.style.fontFamily,
    body: `${cos.style.fontFamily} , 'sans-serif'`,
    heading: `${cos.style.fontFamily} , 'sans-serif'`,
  },
  shadows: {
    outline: "none",
    device: "0px 0px 15px 0px rgba(0,0,0,0.7)",
  },
  colors: {
    brand: {
      "50": "#E5F4FF",
      "100": "#B8E1FF",
      "200": "#8ACEFF",
      "300": "#5CBBFF",
      "400": "#2EA8FF",
      "500": "#0095FF",
      "600": "#0077CC",
      "700": "#005999",
      "800": "#003C66",
      "900": "#001E33",
    },
    brandFull: "#007CD4",
  },
});

export default theme;
